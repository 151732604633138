<template>
  <div class="component-wrap">
    <a-spin :spinning="loading">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" :tab="$t('latestCompletedTraining')">
          <div>
            <a-descriptions
              v-if="currData.userId"
              bordered
              :labelStyle="{ width: '157px', verticalAlign: 'middle' }"
              :contentStyle="{ width: '320px' }"
              :column="2"
              title=" "
            >
              <a-descriptions-item :label="$t('certificateNumber')">
                {{ currData.certNumber }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('functionalCategory')">
                {{ currData.certCategory }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('dateOfIssue')">
                {{ currData.certIssueDate }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('grades')">
                {{ currData.certScore }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('trainingInstitutions')">
                {{ currData.certTrainingInstitution }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('trainingInstitutionAddress')">
                {{ currData.certTrainingInstitutionAddress }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('trainingMaterials')">
                {{ currData.certTrainingMaterial }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('instructor')">
                {{ currData.certInstructor }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('evaluator2')">
                {{ currData.certEvaluator }}
              </a-descriptions-item>
              <!-- <a-descriptions-item :label="$t('auditor2')">
                {{ currData.certReviewer }}
              </a-descriptions-item> -->
              <a-descriptions-item :label="$t('certificateExpirationDate')">
                {{ currData.certExpirationdate }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('initialOrRefresherTraining')">
                {{ currData.certTrainingType }}
              </a-descriptions-item>
            </a-descriptions>
            <a-empty
              v-else
              :image="require('@/assets/image/no_data_3.png')"
              :image-style="{ height: '186px' }"
              style="padding: 60px 0"
            >
              <template #description>
                <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
              </template>
            </a-empty>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('lastCompletedTraining')">
          <div>
            <a-descriptions
              v-if="oldData.userId"
              bordered
              :labelStyle="{ width: '157px' }"
              :contentStyle="{ width: '320px' }"
              :column="2"
              title=" "
            >
              <a-descriptions-item :label="$t('certificateNumber')">
                {{ oldData.certNumber }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('functionalCategory')">
                {{ oldData.certCategory }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('dateOfIssue')">
                {{ oldData.certIssueDate }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('grades')">
                {{ oldData.certScore }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('trainingInstitutions')">
                {{ oldData.certTrainingInstitution }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('trainingInstitutionAddress')">
                {{ oldData.certTrainingInstitutionAddress }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('trainingMaterials')">
                {{ oldData.certTrainingMaterial }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('instructor')">
                {{ oldData.certInstructor }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('evaluator2')">
                {{ oldData.certEvaluator }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('auditor2')">
                {{ oldData.certReviewer }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('certificateExpirationDate')">
                {{ oldData.certExpirationdate }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('initialOrRefresherTraining')">
                {{ oldData.certTrainingType }}
              </a-descriptions-item>
            </a-descriptions>
            <a-empty
              v-else
              :image="require('@/assets/image/no_data_3.png')"
              :image-style="{ height: '186px' }"
              style="padding: 60px 0"
            >
              <template #description>
                <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
              </template>
            </a-empty>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { dateFormat } from "@/utils/tools.js";
import { myDangerouscert } from "@/api/user";
export default {
  setup() {
    const state = reactive({
      activeKey: "1",
      loading: true,
      currData: {},
      oldData: {},
    });

    const dataRender = (data) => {
      let d = JSON.parse(JSON.stringify(data));
      d.certIssueDate = d.certIssueDate
        ? dateFormat(d.certIssueDate * 1000, "YYYY-MM-DD")
        : "-";
      d.certExpirationdate = d.certExpirationdate
        ? dateFormat(d.certExpirationdate * 1000, "YYYY-MM-DD")
        : "-";
      return d;
    };

    myDangerouscert().then((res) => {
      state.loading = false;
      let d = res.data.data;
      state.oldData = dataRender(d.oldMemberDangerousCertDetailDto);
      state.currData = dataRender(d.memberDangerousCertDetailDto);
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
::v-deep(.ant-descriptions-bordered) {
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 12px;
  }
}
</style>
